<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.topic">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Topic</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this topic?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12" lg="8">
							<v-checkbox v-model="topic.use_for_benchmark" label="Include in Benchmark"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="8">
							<v-checkbox v-model="topic.use_for_resources" label="Allow resources to be given this topic"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="8">
							<v-checkbox v-model="topic.hide_from_footer" label="Hide from footer"></v-checkbox>
						</v-col>
					</template>
					<template>
						<v-col cols="12">
							<v-text-field v-model="topic.name" :rules="validations.name" label="Name"></v-text-field>
							<v-text-field v-model="topic.long_name" :rules="validations.name" label="Long name"></v-text-field>
							<v-text-field v-model="topic.meta_title" label="Meta title"></v-text-field>
							<v-text-field v-model="topic.meta_description" label="Meta description"></v-text-field>
							<v-text-field v-model="topic.meta_keywords" label="Meta keywords"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.course">
				<template #title>Related resources</template>
				<ti-form-layout hide-title>
					<template>
						<v-col cols="12">
							<v-data-table :headers="resourceHeaders" :items="topic.resources" item-key="id" :search="search" loading-text="Loading" @click:row="editResource" :item-class="() => 'hover-pointer'" multi-sort>
								<template v-slot:top>
									<v-row justify="end">
										<v-col cols="12" lg="6">
											<v-text-field
												v-model="search"
												label="Search"
												prepend-icon="mdi-magnify"
												class="mx-4"
											></v-text-field>
										</v-col>
									</v-row>
								</template>
							</v-data-table>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";

export default {

	name: "TopicsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		topic: sync('topics/topic')
	},
	data: () => ({
		search: '',
		valid: false,
		validations: {
			name: validationRules('Name', ['required', 'minLength:2']),
		},
		resourceHeaders:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: 'Brand',
				align: 'start',
				sortable: true,
				value: 'brand_id',
			},
			{
				text: 'Type',
				align: 'start',
				sortable: true,
				value: 'type',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('topics/getTopic!', this.id)
			} else {
				this.topic = {
					name: ''
				}
			}
		},
		getTopic() {
			this.$api.topics.show(this.id)
				.then(response => {
					this.topic = response.data
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.message, 'error')
					// this.$toast.handleResponseErrors(this, error)
				})
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.topics.update(this.id, this.topic)
						.then(response => {
							this.$toast.add('Topic updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Topics'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.topics.create(this.topic)
						.then(response => {
							this.$toast.add('Topic created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Topics'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		editResource(resource) {
			let plural = resource.type === 'CaseStudy' ? 'Case_studies' : `${resource.type}s`;
			this.$router.push({name: `${plural}Edit`, params: {id: resource.id}})
		},
		destroy() {
			this.$api.topics.delete(this.id)
				.then(response => {
					this.$toast.add('Topic deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Topics'})
				})
				.catch(error => {
					console.log(error.response);
					console.log('should show error');
					this.$toast.handleResponseErrors(this, error)
				})
		},
	}
}
</script>